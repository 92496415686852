import { css } from '@emotion/css';

export const container = (headerSize: number) => css`
  height: calc(100vh - ${headerSize}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const emptyText = css`
  text-align: center;
  white-space: pre-line;
`;
export const buttonWrapper = css`
  margin: 10px 0px 0px 0px;
`;
