import { MedicalTypeEnum } from '@/graphql/generated/graphql';

export const makeDoctorCallName = (type?: MedicalTypeEnum | null) => {
  switch (type) {
    case MedicalTypeEnum.DentalMedicine:
      return '치과의사';
    case MedicalTypeEnum.KoreanMedicine:
      return '한의사';
    default:
      return '의사';
  }
};
