import React, { FC, PropsWithChildren } from 'react';
import Lottie from 'react-lottie-player';

import LoadingLottie from '@/assets/lotties/loading.json';
import * as style from '@/pages/components/LoadingList/styles';

const LoadingList: FC<PropsWithChildren<{ headerSize: number }>> = (props) => {
  return (
    <div className={style.container(props.headerSize)}>
      <Lottie loop play animationData={LoadingLottie} className={style.lottie} />
    </div>
  );
};
export default LoadingList;
