import { css } from '@emotion/css';

export const container = (headerSize: number) => css`
  height: calc(100vh - ${headerSize}px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const lottie = css`
  width: 100px;
  height: 100px;
`;
