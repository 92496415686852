import { css } from '@emotion/css';

import { COLORS } from '@/styles/colors';

export const doctorContainer = css`
  padding: 16px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  cursor: pointer;
  border-bottom: 1px solid ${COLORS.GRAY_30};
`;
export const profile = css`
  position: relative;
  width: 56px;
  height: 56px;
`;
export const profileImage = (visibility?: boolean | null) => css`
  width: 100%;
  height: 100%;
  border: none;
  object-fit: cover;
  border-radius: 12px;
  ${visibility ? 'opacity:1' : 'opacity: 0.4'}
`;
export const activeIcon = css`
  position: absolute;
  bottom: -2.5px;
  right: -2.5px;
`;

export const doctorContent = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const awardBadge = css`
  margin-left: 6px;
`;

export const reviewContent = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`;

export const starIcon = css`
  margin-right: 4px;
`;

export const specialistDepartments = css`
  padding: 0 5px;
`;
export const verifiedIcon = css`
  margin-left: 2px;
`;
