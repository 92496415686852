import React, { FC, PropsWithChildren } from 'react';

import { Text } from '@/components/typographies';
import { COLORS } from '@/styles/colors';
import { Button } from '@/components/forms';

import * as styles from './styles';
import NotFoundImage from '../../../assets/images/img_notFound.svg';

const EmptyList: FC<
  PropsWithChildren<{
    headerSize: number;
    content: string;
    button?: { text: string; onPress: () => void };
  }>
> = (props) => {
  return (
    <div className={styles.container(props.headerSize)}>
      <NotFoundImage />
      <Text className={styles.emptyText} color={COLORS.GRAY_60} type="body1_500">
        {props.content}
      </Text>
      {props.button && (
        <Button
          className={styles.buttonWrapper}
          styleType={'blueFilled'}
          onClick={props.button?.onPress}
        >
          {props.button.text}
        </Button>
      )}
    </div>
  );
};
export default EmptyList;
