import React, { FC } from 'react';
import { useRouter } from 'next/router';

import { COLORS } from '@/styles/colors';
import { Text } from '@/components/typographies';
import { convertShortAddress } from '@/utils/convertShortAddress';
import * as styles from '@/pages/components/DoctorItem/styles';
import { Doctor } from '@/graphql/generated/graphql';
import { DeepPartial } from '@/utils/createDeepPartial';
import { DoctorAvatar } from '@/components/useCases';
import { makeDoctorCallName } from '@/utils/makeDoctorCallName';

import ActiveIcon from '../../../assets/icons/ic_active_circle.svg';
import StarIcon from '../../../assets/icons/ic_review_star.svg';
import BadgeIcon from '../../../assets/icons/ic_award_badge.svg';
import VerifiedIcon from '../../../assets/icons/ic_verified.svg';

const DoctorItem: FC<{ doctor: DeepPartial<Doctor> }> = (props) => {
  const router = useRouter();
  const { doctor } = props;

  return (
    <li
      className={styles.doctorContainer}
      onClick={() => router.push(`/doctor/${doctor.id}/form/treatment`)}
      key={doctor.id}
    >
      <div className={styles.profile}>
        <DoctorAvatar
          size={'56'}
          src={doctor?.image}
          className={styles.profileImage(doctor.untactOnTreatment)}
        />
        <ActiveIcon
          className={styles.activeIcon}
          color={doctor.untactOnTreatment ? COLORS.GREEN_60 : COLORS.GRAY_50}
          alt="굿닥 비대면진료 의사활성화"
        />
      </div>
      <div>
        <p className={styles.doctorContent}>
          <Text type="body1_700" color={COLORS.GRAY_80}>
            {doctor.name} {makeDoctorCallName(doctor.type)}
          </Text>
          {doctor.showAwardBadge && (
            <BadgeIcon
              className={styles.awardBadge}
              alt="굿닥 비대면진료 의사어워드뱃지"
            />
          )}
        </p>

        <p className={styles.reviewContent}>
          {typeof doctor.reviewSummary?.reviewsCount === 'number' && (
            <>
              <StarIcon
                className={styles.starIcon}
                color={
                  doctor.reviewSummary.reviewsCount > 0 ? COLORS.GRAY_80 : COLORS.GRAY_50
                }
                alt="굿닥 비대면진료 별점"
              />
              {doctor.reviewSummary?.reviewsCount > 0 ? (
                <Text type="caption_700" color={COLORS.GRAY_80}>
                  {doctor?.reviewSummary?.ratingMean}
                </Text>
              ) : (
                <Text type="caption_700" color={COLORS.GRAY_50}>
                  수집중
                </Text>
              )}
              <Text type="caption_400" color={COLORS.GRAY_60}>
                &nbsp;{`(${doctor?.reviewSummary?.reviewsCount})`}
              </Text>
            </>
          )}
          {!!doctor?.doctorSpecialistDepartments?.length &&
            doctor?.doctorSpecialistDepartments[0]?.representative && (
              <>
                <Text
                  type="caption_500"
                  className={styles.specialistDepartments}
                  color={COLORS.GRAY_50}
                >
                  ·
                </Text>
                <VerifiedIcon
                  width={12}
                  height={12}
                  color={COLORS.BLUE_70}
                  className={styles.verifiedIcon}
                  alt="굿닥 비대면진료 인증"
                />
                <Text type="caption_500" color={COLORS.GRAY_80}>
                  {doctor?.doctorSpecialistDepartments[0]?.specialistDepartment?.name}{' '}
                  전문의
                </Text>
              </>
            )}
        </p>
        <p>
          <Text type="caption_500" color={COLORS.GRAY_80}>
            {doctor.hospital?.name}
          </Text>
        </p>
        {doctor.hospital?.address && (
          <p>
            <Text type="caption_500" color={COLORS.GRAY_60}>
              {convertShortAddress(doctor.hospital?.address)}
            </Text>
          </p>
        )}
      </div>
    </li>
  );
};
export default DoctorItem;
