import React from 'react';

import DoctorItem from '@/pages/components/DoctorItem';
import EmptyList from '@/pages/components/EmptyList';
import * as styles from '@/pages/components/DoctorList/styles';
import LoadingList from '@/pages/components/LoadingList';
import { Doctor } from '@/graphql/generated/graphql';
import { DeepPartial } from '@/utils/createDeepPartial';

const DoctorList = (props: { doctors: DeepPartial<Doctor>[]; isLoading: boolean }) => {
  const { doctors, isLoading } = props;

  return (
    <ul className={styles.doctorListContainer}>
      {!isLoading ? (
        !!doctors?.length ? (
          doctors?.map((doctor) => <DoctorItem doctor={doctor} key={doctor.id} />)
        ) : (
          <EmptyList headerSize={48} content={`설정하신 조건에 맞는\n 의사가 없어요`} />
        )
      ) : (
        <LoadingList headerSize={48} />
      )}
    </ul>
  );
};
export default DoctorList;
