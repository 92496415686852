import { useEffect, useState } from 'react';
import axios from 'axios';
import { useRouter } from 'next/router';

import { Location, SelectedLocation } from '@/context/doctor/types';
import { KAKAO_KEYWORD_LOCATION_API, LOCATION } from '@/context/doctor/constants';
import { useDebounce } from '@/hooks/useDebounce';
import { convertShortAddress } from '@/utils/convertShortAddress';
import amplitude from '@/utils/amplitude';
import { fetchKakaoXYLocationAPI, getCurrentLocation } from '@/utils/location';

const useLocation = () => {
  const router = useRouter();
  const { longitude, latitude } = router.query;
  const [openedLocationSettingModal, setOpenedLocationSettingModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<SelectedLocation>({
    latitude: (latitude as string) || LOCATION.latitude,
    longitude: (longitude as string) || LOCATION.longitude,
    keyword: ''
  });
  const [kakaoLocations, setKakaoLocations] = useState<Location[]>([]);
  const [currentLocationLoading, setCurrentLocationLoading] = useState<boolean>();

  const debouncedKeyword = useDebounce(selectedLocation.keyword, 300);

  useEffect(() => {
    if (debouncedKeyword) {
      fetchKakaoKeywordLocationAPI(debouncedKeyword);
    }
  }, [debouncedKeyword]);

  // 모바일에서 위치정보를 주기에 중복으로 조회 및 저장하는 로직 제거
  // 위치정보가 달라지기에 중복으로 API 호출됨
  // useEffect(() => {
  //   (async () => {
  //     const response = await fetchKakaoXYLocationAPI(
  //       Number(selectedLocation.longitude),
  //       Number(selectedLocation.latitude)
  //     );
  //     if (response.status === 200) {
  //       handleLocation(response.data.documents[0]);
  //     }
  //   })();
  // }, []);

  const fetchKakaoKeywordLocationAPI = async (keyword: string) => {
    const response = await axios.get(KAKAO_KEYWORD_LOCATION_API, {
      headers: {
        Authorization: `KakaoAK ${process.env.KAKAO_REST_API_KEY}`
      },
      params: {
        query: keyword,
        size: 5
      }
    });
    if (response.status === 200) {
      setKakaoLocations(response.data.documents);
    }
  };

  const getLocation = () => {
    setCurrentLocationLoading(true);

    getCurrentLocation(
      async (position: GeolocationPosition) => {
        if (position.coords) {
          const response = await fetchKakaoXYLocationAPI(
            position.coords.longitude,
            position.coords.latitude
          );

          if (response.status === 200) {
            handleLocation(response.data.documents[0]);
          }
        }
        setCurrentLocationLoading(false);
      },
      () => {
        setCurrentLocationLoading(false);
      }
    );
  };

  const handleLocation = (location: Location) => {
    setSelectedLocation({
      longitude: location.x,
      latitude: location.y,
      keyword: convertShortAddress(location.address_name || '') as string
    });
    closeLocationSettingModal();
  };

  const searchKeyword = (keyword: string) => {
    setSelectedLocation({ ...selectedLocation, keyword });
  };

  const resetKeyword = () => {
    setSelectedLocation({ keyword: '', latitude: undefined, longitude: undefined });
  };

  const openLocationSettingModal = () => {
    setOpenedLocationSettingModal(!openedLocationSettingModal);
    amplitude.track('untact_addOnSelectLocation_view');
  };

  const closeLocationSettingModal = () => {
    setOpenedLocationSettingModal(false);
  };

  return {
    kakaoLocations,
    selectedLocation,
    handleLocation,
    debouncedKeyword,
    searchKeyword,
    resetKeyword,
    currentLocationLoading,
    openedLocationSettingModal,
    openLocationSettingModal,
    closeLocationSettingModal,
    getCurrentLocation: getLocation
  };
};
export default useLocation;
