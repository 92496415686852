import axios from 'axios';

import { KAKAO_XY_LOCATION_API } from '@/context/doctor/constants';

export const getCurrentLocation = (
  onSuccess: (position: GeolocationPosition) => void,
  onFail: () => void
) => {
  navigator.geolocation.getCurrentPosition(
    async (position: GeolocationPosition) => {
      onSuccess(position);
    },
    () => {
      onFail();
    },
    {
      enableHighAccuracy: false,
      timeout: Infinity
    }
  );
};

export const fetchKakaoXYLocationAPI = async (x: number, y: number) => {
  return await axios.get(KAKAO_XY_LOCATION_API, {
    headers: {
      Authorization: `KakaoAK ${process.env.KAKAO_REST_API_KEY}`
    },
    params: { x, y }
  });
};
